import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultValue'
})
export class DefaultValuePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value === '--'){
      return 'Pro';
    }

    return value;
  }

}
