import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { AppsPage } from './pages/apps/apps.page';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyEnComponent } from './pages/privacy-policy/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyPtComponent } from './pages/privacy-policy/privacy-policy-pt/privacy-policy-pt.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { TermsOfUsePtComponent } from './pages/terms-of-use/terms-of-use-pt/terms-of-use-pt.component';
import { TermsOfUseEnComponent } from './pages/terms-of-use/terms-of-use-en/terms-of-use-en.component';
import { TermsOfUseEsComponent } from './pages/terms-of-use/terms-of-use-es/terms-of-use-es.component';
import { PrivacyPolicyEsComponent } from './pages/privacy-policy/privacy-policy-es/privacy-policy-es.component';
import { GeanappComponent } from './components/geanapp/geanapp.component';
import { BaseurlService } from './services/baseurl.service';

@NgModule({
  declarations: [
    AppComponent,
    AppsPage,
    PrivacyPolicyComponent,
    PrivacyPolicyEnComponent,
    PrivacyPolicyEsComponent,
    PrivacyPolicyPtComponent,
    TermsOfUseComponent,
    TermsOfUseEnComponent,
    TermsOfUseEsComponent,
    TermsOfUsePtComponent,
    HeaderComponent,
    GeanappComponent
  ],
  exports:[
    HeaderComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BaseurlService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
