import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeanApp } from 'src/app/models/gean-app.model';
import { BaseurlService } from 'src/app/services/baseurl.service';
import { GeanTranslatorService } from 'src/app/shared/services/gean-translator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-geanapp',
  templateUrl: './geanapp.component.html',
  styleUrls: ['./geanapp.component.scss'],
})
export class GeanappComponent implements OnInit, OnDestroy {
  @Input() public app: GeanApp;
  public lang = 'en';
  public langSubscription: Subscription;
  public baseUrl = environment.baseUrl;

  constructor(private translatorService: GeanTranslatorService, private baseurlService: BaseurlService) {
    this.baseUrl = this.baseurlService.baseUrl;
  }

  ngOnInit() {
    this.langSubscription = this.translatorService.languageChangeEmitter.subscribe((newLang: string)=>{
      this.lang = newLang;
    });
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }

}
