import { Pipe, PipeTransform } from '@angular/core';
import { GeanTranslatorService } from '../../services/gean-translator.service';


@Pipe({
  name: 'geanTranslate'
})
export class GeanTranslatorPipe implements PipeTransform {
  constructor(private translatorService: GeanTranslatorService){}

  transform(text: string, refresh =0): string {
    const translation: string =  this.translatorService.translate(text);
    console.log(`translation = ${translation}`);
    return translation;

  }

}
