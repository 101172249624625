import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';



@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public async setKeyValue(key: string, value: string){
    await Storage.set({
      key, value
    });
  }

  public async getKeyValue(key: string, defaultValue: string = ''){
    const ret = await Storage.get({ key });
    const value: string = ret.value;
    return value || defaultValue;
  }

}
