import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-hint-en',
  templateUrl: './hint-en.component.html',
  styleUrls: ['./hint-en.component.scss'],
})
export class HintEnComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  public close(){
    this.modalController.dismiss();
  }

}
