import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class BaseurlService {
  public baseUrl = environment.baseUrl;

  constructor() {
    if(window.location.href.includes('localhost')){
      this.baseUrl = '';
    }
  }
}
