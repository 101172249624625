import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GeanApp } from 'src/app/models/gean-app.model';
import { BaseurlService } from 'src/app/services/baseurl.service';
import { GeanTranslatorService } from 'src/app/shared/services/gean-translator.service';
import { environment } from 'src/environments/environment';
import { apps } from './apps';


@Component({
  selector: 'app-apps',
  templateUrl: './apps.page.html',
  styleUrls: ['./apps.page.scss'],
})
export class AppsPage implements OnInit, OnDestroy {
  public baseUrl = '';
  public apps: GeanApp[] = apps;
  public refresh = 0;
  public lang = 'en';
  private langSubscription: Subscription;

  constructor(private baseurlService: BaseurlService,
    private translatorService: GeanTranslatorService) {
    this.baseUrl = this.baseurlService.baseUrl;
  }

  ngOnInit() {
    this.langSubscription = this.translatorService.languageChangeEmitter.subscribe((lang: string) => {
      this.lang = lang;
      this.refresh += 1;
    });
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }

}
