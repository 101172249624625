import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-hint-es',
  templateUrl: './hint-es.component.html',
  styleUrls: ['./hint-es.component.scss'],
})
export class HintEsComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  public close(){
    this.modalController.dismiss();
  }

}
