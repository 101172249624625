// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: '/static/gean-apps',
  firebaseConfig: {
    apiKey: 'AIzaSyAt_5Q0I_fuDPonNT50kB_LWDRQpPE4Xt0',
    authDomain: 'gean-apps.firebaseapp.com',
    projectId: 'gean-apps',
    storageBucket: 'gean-apps.appspot.com',
    messagingSenderId: '871191656922',
    appId: '1:871191656922:web:28765040f178a2441728e3',
    measurementId: 'G-QQZZC67P4J'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
