import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeanTranslatorService } from './shared/services/gean-translator.service';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public refresh = 0;
  public lang = 'en';
  private languageChangeSubscription: Subscription;

  constructor(private translatorService: GeanTranslatorService) {
  }

  ngOnInit(): void {
    const app = initializeApp(environment.firebaseConfig);
    getAnalytics(app);

    this.languageChangeSubscription = this.translatorService.languageChangeEmitter.subscribe((newLang) => {
      this.lang = newLang;
      this.refresh += 1;
    });
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription.unsubscribe();
  }
}
