import { Injectable } from '@angular/core';
import { textsPt } from '../pipes/translator/lang/pt';
import { textsEn } from '../pipes/translator/lang/en';
import { textsEs } from '../pipes/translator/lang/es';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class GeanTranslatorService {
  public languageChangeEmitter = new BehaviorSubject<string>('en');
  private lang = 'en';
  private texts = textsEn;

  constructor(private storageService: StorageService) {
    this.setLangFromDevice();
  }

  public getLang(): string{
    return this.lang;
  }

  public setLangFromDevice(){
    this.storageService.getKeyValue('lang', '')
      .then(async (lang: string)=>{
        if(lang === ''){
          const objLang = await Device.getLanguageCode();
          lang = objLang.value.replace(/-.*/,'');
        }

        this.setLang(lang);
      });
  }
  public translate(text: string){
    console.log(`Translating. Lang = ${this.lang}`);
    if(this.texts[text]){
      return this.texts[text];
    }
    return 'NOT TRANSLATED';
  }

  public setLang(lang: string){
    if(lang === this.lang){
      return;
    }

    // Prevent lang not implemented
    const invalidLang = [ 'en', 'pt', 'es' ].indexOf(lang) === -1;
    if(invalidLang){
      lang = 'en';
    }

    this.lang = lang;

    if(lang === 'en'){
      this.texts = textsEn;
    }else if(lang === 'pt'){
      this.texts = textsPt;
    }else if(lang === 'es'){
      this.texts = textsEs;
    }else{
      this.texts = textsEn;
    }

    this.storageService.setKeyValue('lang', lang);
    this.languageChangeEmitter.next(lang);
  }
}
