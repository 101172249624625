import { GeanApp } from 'src/app/models/gean-app.model';

export const apps: GeanApp[] = [
  {
    url: '/calculator-12c',
    img: 'calculadora-12c.png',
    nameEn: 'Financial Calculator 12C',
    nameEs: 'Calculadora Financiera 12C',
    namePt: 'Calculadora Financeira 12C',
    descriptionEn: `12C Financial Calculator Free can be used by anyone without any training.
     It is similar to HP 12C and can calculate dates, convert interest as well as calculate:
      present value (PV), future value (FV), payment (PMT), number of periods (n),
       interest rate (i). It shows the cash flow as the most advanced finance calculators.
       You can start calculating without reading any manual (opposite of HP 12C).
    ⭐ convert annual interest to monthly;
    ⭐ calculations with dates;
    ⭐ see a month by month report of cashflow;
    ⭐ charts;`,
    descriptionEs: `Calculadora Financiera 12C gratis es una aplicación que puede ser
    utilizada por cualquier persona sin ningún tipo de formación. Es una alternativa
    gratis a la HP 12C y puede calcular fechas futuras, diferencia entre fechas,
    convertir intereses, así como calcular: valor presente (PV), valor futuro (VF), pago
    (PMT), número de períodos (n), tasa de interés (i). Muestra el flujo de caja como
    las calculadoras financieras avanzadas como la HP 12C.
    Características:
    ⭐ cálculo de fechas;
    ⭐ conversión de tasa de interés mensual a anual y viceversa;
    ⭐ tabla con la evolución mes a mes del valor futuro;
    ⭐ gráficos;
    Puede comenzar a calcular sin leer ningún manual (a diferencia de HP12C).`,
    descriptionPt: `12C Calculadora Financeira é um app grátis que pode ser usado por
    qualquer pessoa sem nenhum treinamento. É semelhante à HP 12C e pode calcular datas
    futuras, diferença entre datas, converter taxas de juros (mensal, anual), bem como
    calcular: valor presente (VP), valor futuro (VF), pagamento (PMT), número de períodos
    (n), taxa de juros (i). Mostra o fluxo de caixa tal qual calculadoras financeiras
    avançadas como a HP 12C.
    Características:
    ⭐ cálculo de datas;
    ⭐ conversão de taxa de juros mensal para anual e vice-versa;
    ⭐ tabela com evolução mês a mês do valor futuro;
    ⭐ gráficos;
    É grátis e você pode começar a calcular sem ler nenhum manual.`,
  },
  {
    url: '/loan-calculator',
    img: 'loan-calculator.png',
    nameEn: 'Loan Calculator Pro',
    nameEs: 'Calculadora de Préstamos',
    namePt: 'Calculadora de Empréstimo',
    descriptionEn: `Loan Calculator is an application that allows you to simulate loan payments.
  With an easy-to-use interface, the application can be used to view the amount of installments and the total interest paid.
  The payroll deductible loan is a type of loan that generally uses the pricing table and therefore has fixed-value installments.`,
    descriptionEs: `Calculadora de Préstamos es una aplicación que te permite simular las cuotas de un préstamo.
  Con una interfaz fácil de usar, la aplicación se puede utilizar para ver el monto de las cuotas y el interés total pagado.
  El préstamo deducible de nómina es un tipo de préstamo que generalmente utiliza la tabla de precios y, por lo tanto, tiene
  cuotas de valor fijo.`,
    descriptionPt: `Calcular Empréstimo Consignado é um app que permite simular as parcelas de um empréstimo pessoal com desconto em
   folha. Com uma interface fácil de usar, o aplicativo pode ser usado para ver o valor das parcelas e o total de juros pagos.
  Empréstimo consignado é uma modalidade de empréstimo que geralmente us a tabela Price e, portanto, possui parcelas com valor fixo.`,
  },
  {
    url: '/amortization-simulator',
    img: 'simulador-de-amortizacao.png',
    nameEn: 'Financing amortization (constant amortization)',
    nameEs: 'Amortización de financiación (amortización constante)',
    namePt: 'Amortização de financiamento (tabela SAC)',
    descriptionEn: 'Financing Amortization - Simulate reducing term or installment (Brazilian constant amortization system)',
    descriptionEs: 'Amortización de Financiamiento - Simular plazo o cuota reductora (sistema brasileño de amortización constante)',
    descriptionPt: 'Amortização de Financiamento - Simular diminuir prazo ou parcela (tabela SAC)',
  },
  {
    url: 'https://play.google.com/store/apps/details?id=app.gean.ankiaudio',
    img: 'anki-audio.png',
    nameEn: 'Anki Audio - audio flashcards',
    nameEs: 'Anki Audio',
    namePt: 'Anki Audio revisão flashcards',
    descriptionEn: `Anki Audio is a learning application designed for those who want to optimize
  their time. It is similar to Ankidroid but, instead of writing flashcards, you record short
  audios of subjects you have to remember. Every time an audio is listened, it goes to the end
  of the queue. You can listen to your records while driving or doing house working.
  Ankidroid is a great app for learning languages and other subjects as well. But, sometimes
  is easier to review flashcards by listening to them while doing other daily tasks.
  Anki Audio is a must have complement for Ankidroid.`,
    descriptionEs: `Anki Audio es una aplicación de aprendizaje y revisión diseñada para
  aquellos que desean optimizar su tiempo al estudiar. Es similar a Ankidroid, pero en
  lugar de escribir tarjetas flash, graba audios cortos de temas que necesita estudiar o revisar.
  Cada vez que revisa un audio, va al final de la cola. Puede escuchar sus tarjetas de
  audio mientras conduce o hace las tareas del hogar.
  Ankidroid es una gran aplicación para aprender idiomas y otras materias. Pero a veces
   es más fácil revisar las tarjetas escuchándolas mientras se realizan otras tareas diarias.
   Anki Audio es un complemento imprescindible para Ankidroid.
  Recursos:
  * Decorar contenido para exámenes de ingreso;
  * Decorar material de concurso;
  * Organiza tus audios en cubiertas;
  * Estudie mientras conduce, hace las tareas del hogar, camina, etc.;
  * Aproveche mejor su tiempo para estudiar;`,
    descriptionPt: `Anki Audio é um aplicativo de aprendizagem e revisão desenvolvido para quem
  deseja otimizar seu tempo ao estudar. É semelhante ao Ankidroid, mas, em vez de escrever
  flash cards, você grava pequenos áudios de assuntos que precisa estudar ou fazer revisão.
  Cada vez que você faz revisão de um áudio, ele vai para o fim da fila. Você pode ouvir seus
  audio-cards enquanto dirige ou faz trabalhos domésticos.
  Ankidroid é um ótimo aplicativo para aprender idiomas e também outros assuntos. Mas às vezes
  é mais fácil revisar os flashcards ouvindo-os enquanto faz outras tarefas diárias. Anki
  Audio é um complemento obrigatório para Ankidroid.
  Recursos:
  * Decorar conteúdo para vestibular;
  * Decorar matéria de concurso;
  * Organize seus áudios em decks;
  * Estude enquanto dirige, faz tarefas domésticas, caminha, etc;
  * Aproveite melhor o seu tempo para estudar;`,
  }
];
