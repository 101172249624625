import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeanTranslatorPipe } from './pipes/translator/gean-translator.pipe';
import { PopoverComponent } from './popover/popover.component';
import { GeanSpinnerComponent } from './gean-spinner/spinner.component';
import { IonicModule } from '@ionic/angular';
import { CardComponent } from './card/card.component';
import { MoneyNoDecimalsPipe } from './pipes/money-no-decimals.pipe';
import { DefaultValuePipe } from './pipes/default-value.pipe';
import { HintEnComponent } from './calculator12c/hint-en/hint-en.component';
import { HintEsComponent } from './calculator12c/hint-es/hint-es.component';
import { HintPtComponent } from './calculator12c/hint-pt/hint-pt.component';



@NgModule({
  declarations: [
    GeanTranslatorPipe,
    GeanSpinnerComponent,
    PopoverComponent,
    CardComponent,
    MoneyNoDecimalsPipe,
    DefaultValuePipe,
    HintEnComponent,
    HintEsComponent,
    HintPtComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
    exports: [
        GeanTranslatorPipe,
        GeanSpinnerComponent,
        PopoverComponent,
        CardComponent,
        DefaultValuePipe,
        HintEnComponent,
        HintEsComponent,
        HintPtComponent
    ],
  providers: []
})
export class SharedModule { }
