import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseurlService } from 'src/app/services/baseurl.service';
import { GeanTranslatorService } from 'src/app/shared/services/gean-translator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  // public refresh = 0;
  public lang = 'en';
  public flag = environment.baseUrl + 'assets/flag-en.svg';
  public baseUrl = '';
  private langChangeSubscription: Subscription;

  constructor(private translatorService: GeanTranslatorService, private baseurlService: BaseurlService) {
    this.baseUrl = this.baseurlService.baseUrl;
  }

  ngOnInit() {
    this.langChangeSubscription = this.translatorService.languageChangeEmitter.subscribe((newLang)=>{
      this.lang = newLang;
      // this.refresh += 1;
    });
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  public onChangeLangTo(lang: string){
    console.log(`setting lang to ${lang}`);
    this.lang = lang;
    this.translatorService.setLang(lang);
  }

  langIs(aLang: string){
    return this.translatorService.getLang() === aLang;
  }

}
