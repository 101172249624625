import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyNoDecimals'
})
export class MoneyNoDecimalsPipe implements PipeTransform {
  public lang = 'pt';

  transform(value: number, ...args: unknown[]): string {
      // Prevents "-0"
      if (Math.abs(value) < 0.001) {
        return '0';
      }

      return value.toLocaleString(this.lang, { maximumFractionDigits: 0 });
  }
}
