import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeanTranslatorService } from 'src/app/shared/services/gean-translator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit, OnDestroy {

  public refresh = 0;
  public lang = 'en';
  public baseUrl = environment.baseUrl;
  private languageChangeSubscription: Subscription;

  constructor(private translatorService: GeanTranslatorService) {
  }

  ngOnInit(): void {
    this.languageChangeSubscription = this.translatorService.languageChangeEmitter.subscribe((newLang)=>{
      console.log(`lang = ${newLang}`);
      this.refresh += 1;
      this.lang = newLang;
    });
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription.unsubscribe();
  }

}
